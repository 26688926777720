import React from 'react';
import './RedirectModal.css';
import {Button, Modal} from 'antd';

type Props = {
    title?: string;
    content?: string;
    imageUrl?: string;
    buttonText: string;
    redirectUrl: string;
    onCancel: () => void;
    onRedirect: () => void;
    open: boolean;
    loading: boolean;
}

export const RedirectModal = ({open, title, content, imageUrl, buttonText, loading, onCancel, onRedirect}: Props) => {
    const onRedirectClick = () => {
        if (loading) {
            return;
        }
        onRedirect();
    }

    return (
        <Modal open={open} footer={null} closable={true} onCancel={() => onCancel()}>
            <div className="call-to-action">
                <div className="content">
                    {title && <div className="title">{title}</div>}
                    {content && <div className="description">{content}</div>}
                </div>
                {imageUrl && <img
                    src={imageUrl}
                    alt={'Redirect image'}
                    loading={'eager'}
                />}
                <Button className="call-to-action__button" size={'large'} onClick={() => onRedirectClick()}
                        loading={loading}>
                    {buttonText}
                </Button>
            </div>
        </Modal>
    );
}
